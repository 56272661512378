import cn from 'clsx';
import dino from '../../assets/dino.png';
import styles from './Loading.module.css';

function Loading() {
  return (
    <div className="flex flex-col justify-center items-center w-screen h-screen bg-gray-700">
      <img className="w-32 h-32" src={dino} alt=""></img>
      <div className={cn(styles.ellipsis)}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loading;
