import { lazy, Suspense } from 'react';
import { Loading } from './components';

const App = lazy(() => import('./App'));

function Entry() {
  return (
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  );
}

export default Entry;
