import cn from 'clsx';
import React from 'react';
import { IconType } from 'react-icons';

type Variant = 'primary' | 'secondary' | 'white' | 'red';
type ButtonRef = HTMLButtonElement;
type ButtonProps = React.ComponentPropsWithoutRef<'button'> & {
  variant?: Variant;
  ButtonIcon?: IconType;
};

const baseClasses =
  'flex relative justify-center py-2 px-4 w-full text-base font-medium rounded-md border focus:ring-2 focus:ring-offset-2 focus:outline-none';
const responsiveClasses = '';

function FullWidthButton(
  {
    children,
    className,
    type = 'button',
    variant = 'primary',
    ButtonIcon,
    ...rest
  }: ButtonProps,
  ref: React.ForwardedRef<ButtonRef>,
) {
  return (
    <button
      ref={ref}
      type={type}
      className={cn(
        className,
        baseClasses,
        responsiveClasses,
        `${variant}-button`,
      )}
      {...rest}
    >
      {ButtonIcon && (
        <span className="flex absolute inset-y-0 left-0 items-center pl-3">
          <ButtonIcon className="w-5 h-5" aria-hidden="true" />
        </span>
      )}
      {children}
    </button>
  );
}

const Button_ = React.forwardRef(FullWidthButton);

export default Button_;
