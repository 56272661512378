import { Fragment, useState, ReactElement } from 'react';
import { useAuth } from '../../AuthContext';
import cn from 'clsx';
import { Link, useLocation, useRouter } from 'wouter';
import { Dialog, Transition } from '@headlessui/react';
import { IoCloseOutline, IoMenuOutline } from 'react-icons/io5';

import logo from '../../assets/logo.png';
import { IconType } from 'react-icons';

export type NavigationItem = {
  name: string;
  href: string;
  icon: IconType;
  patterns: string[];
};

export type MainLayoutProps = {
  children: ReactElement;
  navigation: NavigationItem[];
};

export type MenuProps = {
  navigation: NavigationItem[];
  onClick?: Function;
};

export type MenuItemProps = {
  navigationItem: NavigationItem;
  onClick?: Function;
};

export type UserControlProps = {
  onClick?: Function;
};

const UserControl = ({ onClick }: UserControlProps) => {
  const { user, logout, openAuthDialog } = useAuth();

  return (
    <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
      {user ? (
        <button
          className="text-base font-normal text-red-500 hover:underline"
          onClick={() => {
            if (onClick) onClick();
            logout();
          }}
        >
          Đăng Xuất
        </button>
      ) : (
        <button
          className="text-base font-normal text-gray-500 hover:underline"
          type="button"
          onClick={() => {
            if (onClick) onClick();
            openAuthDialog();
          }}
        >
          Đăng Nhập
        </button>
      )}
    </div>
  );
};

const MenuItem = ({ navigationItem, onClick }: MenuItemProps) => {
  const [path] = useLocation();
  const { matcher } = useRouter();

  let match = false;
  if (navigationItem.patterns && navigationItem.patterns.length > 0) {
    match = navigationItem.patterns.reduce((m: boolean, p: string) => {
      const [x] = matcher(p, path);
      return m || x;
    }, false);
  }

  return (
    <Link
      key={navigationItem.name}
      href={navigationItem.href}
      className={cn(
        match
          ? 'bg-gray-100 text-gray-900'
          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
        'group flex items-center px-2 py-2 text-base font-medium rounded-md',
      )}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <navigationItem.icon
        className={cn(
          match ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
          'mr-4 flex-shrink-0 h-6 w-6',
        )}
        aria-hidden="true"
      />
      {navigationItem.name}
    </Link>
  );
};

const Menu = ({ navigation, onClick }: MenuProps) => {
  return (
    <Fragment>
      <div className="flex-shrink-0 flex items-center px-4">
        <img className="h-12 w-auto" src={logo} alt="2play" />
        <div className="block ml-3">
          <Link
            href="/"
            className="text-xl font-base text-green-600 rounded-md"
            onClick={() => {
              if (onClick) onClick();
            }}
          >
            2play<span className="text-gray-500">.gg</span>
          </Link>
          <p className="text-sm text-gray-500">to play good game</p>
        </div>
      </div>

      <nav className="mt-5 px-2 space-y-1">
        {navigation.map((item) => (
          <MenuItem navigationItem={item} key={item.name} />
        ))}
      </nav>
    </Fragment>
  );
};

export default function MainLayout({ children, navigation }: MainLayoutProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <IoCloseOutline
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <Menu
                  navigation={navigation}
                  onClick={() => setSidebarOpen(false)}
                />
              </div>
              <UserControl onClick={() => setSidebarOpen(false)} />
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <Menu navigation={navigation} />
            </div>
            <UserControl />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <IoMenuOutline className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {children}
      </div>
    </div>
  );
}
