import React, { useEffect, useRef } from 'react';

type OutsideClickHandlerProps = React.PropsWithChildren<{
  onOutsideClick: () => void;
}>;

function useOutsideClickHandler(
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // *  `HTMLElement` extends `Element`, which extends `Node`, which extends `EventTarget`

      if (!(event.target instanceof Node)) {
        return;
      }

      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

function OutsideClickHandler({
  children,
  onOutsideClick,
}: OutsideClickHandlerProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOutsideClickHandler(wrapperRef, onOutsideClick);

  return <div ref={wrapperRef}>{children}</div>;
}

export default OutsideClickHandler;
