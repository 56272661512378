import cn from 'clsx';
import React, { MouseEventHandler } from 'react';

type PingingRoundedButtonProps = React.PropsWithChildren<{
  className?: string;
  enable?: boolean;
  pinging?: boolean;
  variant?: 'green' | 'red' | 'yellow';
  onClick?: MouseEventHandler<HTMLButtonElement>;
}>;

function PingingRoundedButton({
  children,
  className,
  enable = false,
  pinging = false,
  variant = 'green',
  onClick,
}: PingingRoundedButtonProps) {
  // * must write classes explicitly because tailwindcss purge
  return (
    <div className={cn('relative rounded-full', className)}>
      <div
        className={cn(
          'absolute inline-flex h-full w-full rounded-full opacity-50',
          enable &&
            cn(
              pinging && 'animate-ping ',
              variant === 'green' && 'bg-green-500',
              variant === 'red' && 'bg-red-500',
              variant === 'yellow' && 'bg-yellow-500',
            ),
        )}
      />
      <button
        className={cn(
          'relative inline-flex rounded-full h-full w-full p-2 focus:outline-none',
          enable &&
            cn(
              'text-white',
              variant === 'green' && 'bg-green-500',
              variant === 'red' && 'bg-red-500',
              variant === 'yellow' && 'bg-yellow-500',
            ),
          !enable &&
            cn(
              'border border-gray-300 hover:border-gray-400',
              variant === 'green' && 'text-green-300 hover:text-green-500',
              variant === 'red' && 'text-red-300 hover:text-red-500',
              variant === 'yellow' && 'text-yellow-300 hover:text-yellow-500',
            ),
        )}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
}

export default PingingRoundedButton;
