import { useIsMounted } from '@react-hookz/web';
import {
  DependencyList,
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from 'react';

type TPromise<P extends Promise<any>> = P extends Promise<infer T> ? T : never;

type TPromiseFn = (...args: any[]) => Promise<any>;

export type TAsyncState<T> =
  | {
      loading: boolean;
      error?: undefined;
      value?: undefined;
    }
  | {
      loading: true;
      error?: Error | undefined;
      value?: T;
    }
  | {
      loading: false;
      error: Error;
      value?: undefined;
    }
  | {
      loading: false;
      error?: undefined;
      value: T;
    };

type TStateFromFn<T extends TPromiseFn> = TAsyncState<TPromise<ReturnType<T>>>;

export type TAsyncFnReturn<T extends TPromiseFn = TPromiseFn> = [
  TStateFromFn<T>,
  T,
  Dispatch<SetStateAction<TStateFromFn<T>>>,
];

export default function useAsyncFn<T extends TPromiseFn>(
  fn: T,
  deps: DependencyList = [],
  initialState: TStateFromFn<T> = { loading: false },
): TAsyncFnReturn<T> {
  const lastCallId = useRef(0);
  const isMounted = useIsMounted();
  const [state, set] = useState<TStateFromFn<T>>(initialState);

  const callback = useCallback(
    (...args: Parameters<T>): ReturnType<T> => {
      const callId = ++lastCallId.current;
      set((prevState) => ({ ...prevState, loading: true }));

      return fn(...args).then(
        (value) => {
          isMounted() &&
            callId === lastCallId.current &&
            set({ value, loading: false });

          return value;
        },
        (error) => {
          isMounted() &&
            callId === lastCallId.current &&
            set({ error, loading: false });

          return error;
        },
      ) as ReturnType<T>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );

  return [state, callback as unknown as T, set];
}
