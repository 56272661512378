import crs from 'crypto-random-string';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { apiClient } from './apiClient';
import { Loading } from './components';
import AuthDialog from './features/Auth/AuthDialog';
import { TUser } from './features/User';
import {
  LS_KEY_GUEST_ID,
  LS_KEY_GUEST_USERNAME,
  LS_KEY_TOKEN,
} from './shared/constants';
import useAsyncFn from './useAsyncFn';

type TAuthContext = {
  user?: TUser;
  openAuthDialog: (register?: boolean) => void;
  login: (data: { email: string; password: string }) => Promise<void>;
  register: (data: {
    email: string;
    fullname: string;
    password: string;
    repassword: string;
  }) => Promise<void>;
  logout: () => void;
};

const AuthContext = React.createContext<TAuthContext | null>(null);

async function bootstrap() {
  const token = window.localStorage.getItem(LS_KEY_TOKEN);

  if (token) {
    const res = await apiClient('/user', { token });

    return { ...res.data, token } as TUser;
  }

  return undefined;
}

export function AuthProvider(props: React.PropsWithChildren<{}>) {
  const [bootstrapState, doBootstrap, setBootstrapState] = useAsyncFn(
    bootstrap,
    [],
    { loading: true },
  );

  const [authDialogOpen, setAuthDialogOpen] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const openAuthDialog = (flag?: boolean) => {
    flag ? setIsRegister(flag) : setIsRegister(false);
    setAuthDialogOpen(true);
  };
  const closeAuthDialog = () => setAuthDialogOpen(false);

  const login = useCallback(
    (data) =>
      apiClient('/user/login', { data }).then((res) => {
        if (!res.currentToken) {
          throw new Error('Server responded with no token.');
        }

        window.localStorage.setItem(LS_KEY_TOKEN, res.currentToken);
        setBootstrapState((prev) => ({
          ...prev,
          value: { ...res.data, token: res.currentToken },
        }));
      }),
    [setBootstrapState],
  );

  const logout = useCallback(() => {
    window.localStorage.removeItem(LS_KEY_TOKEN);
    setBootstrapState((prev) => ({ ...prev, value: undefined }));
  }, [setBootstrapState]);

  const register = useCallback(
    (data) =>
      apiClient('/user/register', { data }).then((res) => {
        if (!res.currentToken) {
          throw new Error('Server responded with no token.');
        }

        window.localStorage.setItem(LS_KEY_TOKEN, res.currentToken);
        setBootstrapState((prev) => ({
          ...prev,
          value: { ...res.data, token: res.currentToken },
        }));
      }),
    [setBootstrapState],
  );

  const value = useMemo(
    () => ({
      openAuthDialog,
      login,
      logout,
      register,
      user: bootstrapState.value,
    }),
    [bootstrapState.value, login, logout, register],
  );

  useEffect(() => {
    doBootstrap();
  }, [doBootstrap]);

  if (bootstrapState.loading) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider value={value}>
      {props.children}
      <AuthDialog
        open={authDialogOpen}
        register={isRegister}
        onLogin={() => setIsRegister(false)}
        onRegister={() => setIsRegister(true)}
        onClose={closeAuthDialog}
      />
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(`'useAuth' must be used within a 'AuthProvider'`);
  }

  return context;
}

export function useGuest() {
  const [guest, setGuest] = useState<TUser>();
  const guestId = window.localStorage.getItem(LS_KEY_GUEST_ID);
  const guestUsername = window.localStorage.getItem(LS_KEY_GUEST_USERNAME);

  useEffect(() => {
    if (guestId && guestUsername) {
      setGuest({
        id: -guestId,
        email: `${guestId}@guest.2play.gg`,
        fullname: guestUsername,
        token: '',
      });
    }
  }, [guestId, guestUsername]);

  const createGuest = useCallback((username: string) => {
    // guestId: negative, starts with '9', length is 7
    const id = '9' + crs({ type: 'numeric', length: 6 });

    window.localStorage.setItem(LS_KEY_GUEST_ID, id);
    window.localStorage.setItem(LS_KEY_GUEST_USERNAME, username);
    setGuest({
      id: -id,
      email: `${id}@guest.2play.gg`,
      fullname: username,
      token: '',
    });
  }, []);

  const removeGuest = useCallback(() => {
    window.localStorage.removeItem(LS_KEY_GUEST_ID);
    window.localStorage.removeItem(LS_KEY_GUEST_USERNAME);
    setGuest(undefined);
  }, []);

  return useMemo(
    () => ({ guest, createGuest, removeGuest }),
    [createGuest, guest, removeGuest],
  );
}

export default AuthContext;
