import humps from 'humps';

export type ClientOptions = {
  data?: any;
  token?: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: HeadersInit;
  customConfig?: RequestInit;
};

export type ApiResponse<TData> = {
  data: TData;
  status: string;
  currentToken?: string;
};

export function createClient(baseURL: string) {
  return async function client<TData = any>(
    endpoint: string,
    {
      data,
      token,
      method,
      headers: customHeaders,
      ...customConfig
    }: ClientOptions,
  ) {
    const headers = Object.assign(
      {},
      data && { 'Content-Type': 'application/json' },
      token && { 'x-token': token },
      customHeaders,
    );
    const config: RequestInit = {
      method: method ? method : data ? 'POST' : 'GET',
      body: data ? JSON.stringify(humps.decamelizeKeys(data)) : undefined,
      headers,
      ...customConfig,
    };

    try {
      const response = await window.fetch(`${baseURL}${endpoint}`, config);
      const json = await response.json();

      if (!response.ok) {
        // const m = `Request to ${response.url} returns ${response.status} (${response.statusText})`;

        throw new Error(json.reason || 'MSG_UNDEFINED_ERROR');
      }

      const normalizedJSON = humps.camelizeKeys(
        json as object,
      ) as ApiResponse<TData>;
      const ret: ApiResponse<TData> = {
        ...normalizedJSON,
        currentToken: response.headers.get('x-token') || undefined,
      };

      return ret;
    } catch (error) {
      throw error;
    }
  };
}
