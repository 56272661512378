import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { IoLogInOutline, IoPersonOutline } from 'react-icons/io5';
import { useAuth } from '../../AuthContext';
import Divider from '../../components/Divider';
import FullWidthButton from '../../components/FullWidthButton';
import useAsyncFn from '../../useAsyncFn';

type RegisterProps = { onLogin: Function };

type FormInput = {
  email: string;
  fullname: string;
  password: string;
  repassword: string;
};

function Register({ onLogin }: RegisterProps) {
  const { handleSubmit, getValues, register } = useForm<FormInput>();
  const { register: tpRegister } = useAuth();
  const [state, doRegister] = useAsyncFn(tpRegister, [tpRegister]);

  return (
    <div className="space-y-4 w-full">
      <div>
        <h2 className="text-2xl text-center">Đăng Ký</h2>
      </div>
      <div className="text-sm text-center text-gray-500">
        Bạn cần đăng ký để tạo phòng hoặc tham gia các phòng có chế độ riêng tư.
      </div>
      <Transition
        as={Fragment}
        show={!state.loading && !!state.error}
        enter="transition-all duration-150 delay-100"
        enterFrom="h-0 opacity-0"
        enterTo="h-auto opacity-100"
        leave="transition-all duration-150"
        leaveFrom="h-auto opacity-100"
        leaveTo="h-0 opacity-0"
      >
        <div className="py-2 px-4 text-sm text-white bg-red-500 rounded">
          {state.error?.message || 'Có lỗi không xác định.'}
        </div>
      </Transition>
      <form
        className="space-y-6"
        onSubmit={handleSubmit((values) => doRegister(values))}
      >
        <input type="hidden" name="remember" defaultValue="true" />
        <div className="-space-y-px rounded-md shadow-sm">
          <div>
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              id="email"
              type="email"
              className="block relative py-2 px-3 w-full text-base placeholder-gray-500 text-gray-900 rounded-none rounded-t-md border border-gray-300 appearance-none focus:z-10 focus:border-green-500 focus:ring-green-500 focus:outline-none"
              placeholder="Email"
              {...register('email', { required: true })}
            />
          </div>
          <div>
            <label htmlFor="fullname" className="sr-only">
              Tên
            </label>
            <input
              id="fullname"
              type="text"
              className="block relative py-2 px-3 w-full text-base placeholder-gray-500 text-gray-900 rounded-none border border-gray-300 appearance-none focus:z-10 focus:border-green-500 focus:ring-green-500 focus:outline-none"
              placeholder="Tên"
              {...register('fullname', { required: true })}
            />
          </div>
          <div>
            <label htmlFor="password" className="sr-only">
              Mật Khẩu
            </label>
            <input
              id="password"
              type="password"
              className="block relative py-2 px-3 w-full text-base placeholder-gray-500 text-gray-900 rounded-none border border-gray-300 appearance-none focus:z-10 focus:border-green-500 focus:ring-green-500 focus:outline-none"
              placeholder="Mật Khẩu"
              {...register('password', { required: true })}
            />
          </div>
          <div>
            <label htmlFor="repassword" className="sr-only">
              Mật khẩu
            </label>
            <input
              id="repassword"
              type="password"
              className="block relative py-2 px-3 w-full text-base placeholder-gray-500 text-gray-900 rounded-none rounded-b-md border border-gray-300 appearance-none focus:z-10 focus:border-green-500 focus:ring-green-500 focus:outline-none"
              placeholder="Nhập lại Mật Khẩu"
              {...register('repassword', {
                required: true,
                validate: (value) => value === getValues('password'),
              })}
            />
          </div>
        </div>
        <div>
          <FullWidthButton
            type="submit"
            disabled={state.loading}
            variant="primary"
            ButtonIcon={IoPersonOutline}
          >
            Đăng Ký
          </FullWidthButton>
        </div>
      </form>
      <Divider label="hoặc" />
      <div>
        <FullWidthButton
          type="button"
          variant="red"
          ButtonIcon={IoLogInOutline}
          onClick={() => onLogin()}
        >
          Đăng Nhập
        </FullWidthButton>
      </div>
    </div>
  );
}

export default Register;
